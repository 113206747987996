import { css } from '@emotion/css';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types';
import { Colors } from '../styles/colors';

type ITextAreaProps = {
  placeHolder: string;
  defaultValue?: any;
  formOptions: UseFormRegisterReturn;
  errors?: any;
  label?: string;
};

export const TextArea = (props: ITextAreaProps) => {
  const { defaultValue, placeHolder, formOptions, label, errors } = props;

  return (
    <div className={textAreaContainerStyle}>
      {label && <h3 className={textAreaLabelStyle}>{label}</h3>}
      <textarea
        className={textAreaStyle}
        placeholder={placeHolder}
        defaultValue={defaultValue}
        {...formOptions}
      />
      {/* {!isEmpty(errors) && <span>{errors[formOptions.name].type}</span>} */}
    </div>
  );
};

const textAreaContainerStyle = css`
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

const textAreaLabelStyle = css`
  margin-bottom: 0.5rem;
`;

const textAreaStyle = css`
  width: 100%;
  height: 10rem;
  border-radius: 1rem;
  padding: 1rem;
  border: none;
  box-sizing: border-box;
  resize: none;
  border: 1px ${Colors.GRAY} solid;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }
`;
