import React, { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { PublicRoute } from './PublicRoute';
import { Routes } from './routes';
import { SignInPage } from '../pages/sign-in-page/SignInPage';
import { PrivateRoute } from './PrivateRoute';
import { NotFoundPage } from '../pages/not-found-page/NotFoundPage';
import { userStore } from '../shared/data/stores/user-store';
import { Header } from '../shared/components/Header';
import { observer } from 'mobx-react';
import { NotificationsPage } from '../pages/notifications-page/NotificationsPage';
import { UsersPage } from '../pages/users-page/UsersPage';
import { DashboardPage } from '../pages/dashboard-page/DashboardPage';
import { MessagingCardsPage } from '../pages/messaging-cards-page/MessagingCardsPage';
import { ProductsPage } from '../pages/products-page/ProductsPage';
import { DeleteAccountPage } from '../pages/delete-account-page/DeleteAccountPage';
import { ReportUsers } from '../pages/report-users-page/ReportUsers';

export const AppRouter = observer(() => {
  const isAuthorized = !!userStore.getUser();

  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <PrivateRoute
          component={DashboardPage}
          path={Routes.DASHBOARD}
          isAuthorized={isAuthorized}
          exact
        />
        <PrivateRoute
          component={NotificationsPage}
          path={Routes.NOTIFICATIONS}
          isAuthorized={isAuthorized}
          exact
        />
        <PrivateRoute
          component={MessagingCardsPage}
          path={Routes.MESSAGING_CARDS}
          isAuthorized={isAuthorized}
          exact
        />
        <PrivateRoute
          component={UsersPage}
          path={Routes.USERS}
          isAuthorized={isAuthorized}
          exact
        />
        <PrivateRoute
          component={ProductsPage}
          path={Routes.PRODUCTS}
          isAuthorized={isAuthorized}
          exact
        />
        <PrivateRoute
          component={ReportUsers}
          path={Routes.REPORTED_USERS}
          isAuthorized={isAuthorized}
          exact
        />
        <PublicRoute
          component={SignInPage}
          path={Routes.SIGN_IN}
          isAuthorized={isAuthorized}
          exact
        />
        <PublicRoute
          component={DeleteAccountPage}
          path={Routes.DELETE_ACCOUNT}
          isAuthorized={isAuthorized}
          exact
        />
        <PublicRoute component={NotFoundPage} isAuthorized={false} />
      </Switch>
    </BrowserRouter>
  );
});
