import { useQuery, UseQueryResult } from "react-query";
import { IAnalytics, ITaskSchedulerStatus } from "../../data/types/server-types";
import { http } from "../http";
import { CacheKeys } from "./configuration";

export function useAnalytics(): UseQueryResult<IAnalytics, any> {
  return useQuery<IAnalytics, any>(CacheKeys.ANALYTICS, http.getAnalytics, {
    refetchInterval: 5000
  })
}

export function useTaskSchedulerStatus(): UseQueryResult<ITaskSchedulerStatus, any> {
  return useQuery<ITaskSchedulerStatus, any>(CacheKeys.TASK_SCHEDULER_STATUS, http.getTaskSchedulerStatus, {
    refetchInterval: 5000
  })
}