import { css } from '@emotion/css';
import { useQueryClient } from 'react-query';
import { Button } from '../../shared/components/Button';
import { INotification } from '../../shared/data/types/server-types';
import { CacheKeys } from '../../shared/services/api/configuration';
import { useCancelNotification } from '../../shared/services/api/notifications';
import { Colors } from '../../shared/styles/colors';
import { formatDateTimeAsString } from '../../shared/utils/format-date';

interface INotificationsTableProps {
  notifications: INotification[];
}

export const NotificationsTable = (props: INotificationsTableProps) => {
  const { notifications } = props;
  const cancelNotification = useCancelNotification();
  const queryClient = useQueryClient();

  const onNotificationCancel = async (id: string) => {
    await cancelNotification({ id });
    await queryClient.refetchQueries(CacheKeys.NOTIFICATIONS);
    await queryClient.refetchQueries(CacheKeys.TASK_SCHEDULER_STATUS);
  };

  const parseFilters = (filters: object[]) => {
    // @ts-ignore
    return filters.map((f) => f.value).join(';');
  };

  return (
    <table className={tableStyle}>
      <th>ID</th>
      <th>Title</th>
      <th>Message</th>
      <th>Identifier</th>
      <th>Type</th>
      <th>Status</th>
      <th>Filters</th>
      <th>Personal Notification For</th>
      <th>Created At</th>
      <th>Scheduled For</th>
      <th>Sent At</th>
      <th>Failed At</th>
      <th>Actions</th>
      <tbody>
        {notifications.map((notification: INotification, index: number) => {
          return (
            <tr key={index}>
              <td>{notification._id}</td>
              <td>{notification.title}</td>
              <td>{notification.message}</td>
              <td>{notification.name}</td>
              <td>{notification.type}</td>
              <td>{notification.status}</td>
              <td>{parseFilters(notification.filters)}</td>
              <td>{notification.personalNotificationFor}</td>
              <td>{formatDateTimeAsString(notification.createdOn)}</td>
              <td>
                {notification.scheduledAt
                  ? formatDateTimeAsString(notification.scheduledAt)
                  : '-'}
              </td>
              <td>
                {notification.sentAt
                  ? formatDateTimeAsString(notification.sentAt)
                  : '-'}
              </td>
              <td>
                {notification.failedAt
                  ? formatDateTimeAsString(notification.failedAt)
                  : '-'}
              </td>
              <td>
                {notification.isScheduled &&
                  !notification.sentAt &&
                  notification.status === 'SCHEDULED' && (
                    <Button
                      title={'Cancel'}
                      onClick={() => onNotificationCancel(notification._id)}
                      style={{
                        backgroundColor: Colors.RED,
                        padding: 5,
                        borderRadius: 0,
                      }}
                    />
                  )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const tableStyle = css`
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
  display: block;
  overflow-x: auto;
  white-space: nowrap;

  th {
    text-align: left;
    border-bottom: 1px solid ${Colors.GRAY};
  }

  tr:nth-of-type(2n) {
    background-color: ${Colors.LIGHT_BLUE};
  }

  td {
    padding: 0.7rem;
    border: none;
  }
`;
