import { observer } from 'mobx-react';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { userStore } from '../shared/data/stores/user-store';
import { Routes } from './routes';

interface IPublicRouteProps {
  component: React.FC;
  isAuthorized: boolean;
  [rest: string]: any;
}

export const PublicRoute = (props: IPublicRouteProps) => {
  const { component: Component, isAuthorized, rest } = props;

  return (
    <Route
      {...rest}
      render={(props: any) =>
        isAuthorized ? (
          <Redirect to={Routes.DASHBOARD} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
