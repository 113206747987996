export enum Colors {
  BLACK = '#262626',
  GRAY = '#757575',
  WHITE = '#ffffff',
  LIGHT_BLUE = '#E9F5F7',
  BLUE = '#5DC3D5',
  DARK_BLUE = '#357492',
  LIGHT_GREEN = '#B3E38A',
  GREEN = '#80A64F',
  ORANGE = '#EAB462',
  RED = '#DF7153',
  SHADOW_COLOR = 'rgba(18, 72, 88, 0.12)'
}
