import { css } from '@emotion/css';
import { useState } from 'react';
import Loader from 'react-loader-spinner';
import { Button } from '../../shared/components/Button';
import { Container } from '../../shared/components/Container';
import { HeroSection } from '../../shared/components/HeroSection';
import { PAGINATION_LIMIT } from '../../shared/data/constants';
import { useNotifications } from '../../shared/services/api/notifications';
import { Colors } from '../../shared/styles/colors';
import { CreateNotificationModal } from './CreateNotificationModal';
import { NotificationsTable } from './NotificationsTable';

export const NotificationsPage = () => {
  const [skip, setSkip] = useState<number>(0);
  const [createNotificationModalVisible, setCreateNotificationModalVisible] =
    useState<boolean>(false);

  const {
    data: notifications,
    isLoading,
    isError,
  } = useNotifications({
    skip,
    limit: PAGINATION_LIMIT,
  });

  if (isError) {
    return <h1>Something went wrong</h1>;
  }

  if (isLoading || !notifications) {
    return (
      <div className={loaderStyle}>
        <Loader type="ThreeDots" color={Colors.BLUE} height={80} width={80} />
      </div>
    );
  }

  const onPreviousClickHandler = () => {
    setSkip((skip) => Math.max(skip - PAGINATION_LIMIT, 0));
  };

  const onNextClickHandler = () => {
    if (notifications.length === 0) {
      setSkip((skip) => Math.max(skip - PAGINATION_LIMIT, 0));
    } else {
      setSkip(skip + PAGINATION_LIMIT);
    }
  };

  const onNewClickHandler = () => {
    setCreateNotificationModalVisible(true);
  };

  return (
    <>
      <Container>
        <HeroSection
          backgroundColor={Colors.LIGHT_GREEN}
          title={'Notifications'}
        />
        <div className={actionButtonsContainerStyle}>
          <Button title={'New Notification'} onClick={onNewClickHandler} />
        </div>
        <section className={dashboardStyle}>
          {notifications.length > 0 ? (
            <NotificationsTable notifications={notifications} />
          ) : (
            <div>Didn't find what you are looking for? Contact the devs!</div>
          )}
          <div className={paginationContainerStyle}>
            <Button
              title={'<'}
              onClick={onPreviousClickHandler}
              disabled={skip === 0}
            />
            <span className={paginationIndexStyle}>
              {skip / PAGINATION_LIMIT}
            </span>
            <Button
              title={'>'}
              onClick={onNextClickHandler}
              disabled={notifications.length === 0}
            />
          </div>
        </section>
      </Container>
      <CreateNotificationModal
        isOpen={createNotificationModalVisible}
        setIsOpen={setCreateNotificationModalVisible}
      />
    </>
  );
};

const actionButtonsContainerStyle = css`
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-right: 2rem;

  & > * {
    margin-left: 0.5rem;
  }
`;

const dashboardStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-x: scroll;
`;

const paginationContainerStyle = css`
  padding: 2rem;
`;

const paginationIndexStyle = css`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const loaderStyle = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
