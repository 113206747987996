import { css } from '@emotion/css';
import { Container } from '../../shared/components/Container';
import { HeroSection } from '../../shared/components/HeroSection';
import { Loader } from '../../shared/components/Loader';
import { useAnalytics } from '../../shared/services/api/analytics';
import { Colors } from '../../shared/styles/colors';
import { AnalyticsCard } from './AnalyticsCard';

export const DashboardPage = () => {
  const { data: analytics, isLoading, isError } = useAnalytics();

  if (isError) {
    return <h1>Something went wrong</h1>;
  }

  if (isLoading || !analytics) {
    return <Loader />;
  }

  return (
    <Container>
      <HeroSection backgroundColor={Colors.ORANGE} title={'Dashboard'} />
      <h2 style={{ textAlign: 'center', marginTop: '2rem' }}>General</h2>
      <section className={dashboardStyle}>
        <AnalyticsCard title={'Users'} count={analytics.userCount} />
        <AnalyticsCard
          title={'Facebook Users'}
          count={analytics.facebookUserCount}
        />
        <AnalyticsCard
          title={'Total listings'}
          count={analytics.totalListingCount}
        />
        <AnalyticsCard
          title={'Given listings'}
          count={analytics.givenListingCount}
        />
        <AnalyticsCard
          title={'Active Listings'}
          count={analytics.activeListingCount}
        />
        <AnalyticsCard
          title={'Messages Sent'}
          count={analytics.messagesCount}
        />
      </section>
    </Container>
  );
};

const dashboardStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;
  flex-wrap: wrap;
`;
