import { css } from '@emotion/css';
import { SubmitHandler, useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useQueryClient } from 'react-query';
import { Button } from '../../shared/components/Button';
import { Separator } from '../../shared/components/Separator';
import { SubmitButton } from '../../shared/components/SubmitButton';
import { TextArea } from '../../shared/components/TextArea';
import { TextInput } from '../../shared/components/TextInput';
import { CacheKeys } from '../../shared/services/api/configuration';
import { useCreateNotification } from '../../shared/services/api/notifications';
import { Colors } from '../../shared/styles/colors';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDateAsString } from '../../shared/utils/format-date';
import { useState } from 'react';
import { showToast, ToastTypeEnum } from '../../shared/utils/toast';
import {
  ExternalNavigationEnum,
  NavigationScreenEnum,
  NotificationSubtypeEnum,
  NotificationTypeEnum,
} from '../../shared/data/enums';
import { useCompanies } from '../../shared/services/api/companies';
import { countries, languages } from '../../shared/constants';

type CreateNotificationData = {
  title: string;
  message: string;
  name: string;
  scheduledAtDate: Date;
  scheduledAtTime: string;
  isScheduled: boolean;
  includedBoroughs: string;
  userId: string;
  type: NotificationTypeEnum;
  subtype: NotificationSubtypeEnum;
  navigateTo: NavigationScreenEnum;
  companyId: any;
  language?: string;
  country?: string;
};

interface ICreateNotificationModalProps {
  isOpen: boolean;
  setIsOpen(value: boolean): void;
}

export const CreateNotificationModal = (
  props: ICreateNotificationModalProps,
) => {
  const { isOpen, setIsOpen } = props;
  const [isSending, setIsSending] = useState<boolean>(false);
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateNotificationData>();
  const queryClient = useQueryClient();
  const { data: companyData, isLoading, isFetching } = useCompanies();
  const createNotification = useCreateNotification();

  const title = watch('title');
  const message = watch('message');
  const name = watch('name');
  const scheduledAtDate = watch('scheduledAtDate');
  const scheduledAtTime = watch('scheduledAtTime');
  const isScheduled = watch('isScheduled');
  const includedBoroughs = watch('includedBoroughs');
  const type = watch('type');
  const userId = watch('userId');
  const language = watch('language');
  const country = watch('country');

  const navigationScreenValues = Object.values(NavigationScreenEnum).map(
    (value) => {
      return {
        label: value.replace(/[A-Z]/g, ' $&').trim(),
        value: value,
      };
    },
  );

  const externalNavigationScreenValues = Object.values(
    ExternalNavigationEnum,
  ).map((value) => {
    return {
      label: value.replace(/[A-Z]/g, ' $&').trim(),
      value: value,
    };
  });

  const closeClickHandler = () => {
    setIsOpen(false);
  };

  const sendNotification = async (data: any) => {
    const { scheduledAtDate, scheduledAtTime, isScheduled, ...rest } = data;
    /**
     * Check if scheduled
     */
    let scheduledAt = undefined;
    if (data.isScheduled && scheduledAtDate && scheduledAtTime) {
      const [hours, minutes] = scheduledAtTime.split(':');
      scheduledAt = moment(scheduledAtDate)
        .set({
          h: parseInt(hours),
          m: parseInt(minutes),
        })
        .toDate();
    }

    await createNotification({ ...rest, scheduledAt, isScheduled });
  };

  const onSubmit: SubmitHandler<CreateNotificationData> = async (
    data: CreateNotificationData,
  ) => {
    try {
      setIsSending(true);
      await sendNotification(data);
      await queryClient.refetchQueries(CacheKeys.NOTIFICATIONS);
      await queryClient.refetchQueries(CacheKeys.TASK_SCHEDULER_STATUS);
      setIsOpen(false);
      showToast(ToastTypeEnum.SUCCESS, 'Notification created successfully!');
    } catch (error) {
      console.log(error);
    } finally {
      setIsSending(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Modal isOpen={isOpen} style={createNotificationModalStyle}>
      <form onSubmit={handleSubmit(onSubmit)} className={formStyle}>
        <section className={formSectionStyle}>
          <h2>Create a new notification</h2>
          <Separator />
          <TextInput
            placeHolder={'Title'}
            formOptions={register('title', { required: true })}
            errors={errors}
            label={'Title'}
          />
          <TextArea
            placeHolder={'Message'}
            formOptions={register('message', { required: true })}
            errors={errors}
            label={'Message'}
          />
          <TextInput
            placeHolder={'Identifier (must be unique)'}
            formOptions={register('name', { required: true })}
            errors={errors}
            label={'Identifier'}
          />
        </section>
        <section className={formSectionStyle}>
          <h2>Behaviour</h2>
          <Separator />
          <select {...register('type')}>
            <option>Select type</option>
            <option value={NotificationTypeEnum.SEGMENT}>
              Send to all users.
            </option>
            <option value={NotificationTypeEnum.FILTER_LOCATION}>
              Send based on location.
            </option>
            <option value={NotificationTypeEnum.PERSONAL}>
              Send to a specific user.
            </option>
            <option value={NotificationTypeEnum.COMPANY}>
              Send to a specific company.
            </option>
            <option value={NotificationTypeEnum.FILTER_LANGUAGE}>
              Send based on language.
            </option>
            <option value={NotificationTypeEnum.FILTER_COUNTRY}>
              Send based on country.
            </option>
          </select>
          {(type === NotificationTypeEnum.SEGMENT ||
            type === NotificationTypeEnum.COMPANY ||
            type === NotificationTypeEnum.FILTER_LANGUAGE ||
            type === NotificationTypeEnum.FILTER_COUNTRY) && (
            <select {...register('subtype')}>
              <option value={NotificationSubtypeEnum.ADMIN_MESSAGE}>
                Generic notification (redirect to notification screen).
              </option>
              <option value={NotificationSubtypeEnum.MEMBERSHIP}>
                Generic notification (redirect to membership screen).
              </option>
            </select>
          )}
          {type === NotificationTypeEnum.FILTER_LOCATION && (
            <TextArea
              placeHolder={
                'Separate boroughs by a semicolon. ex. Bromley;Hackney'
              }
              formOptions={register('includedBoroughs', { required: true })}
              errors={errors}
              label={'Included boroughs'}
            />
          )}
          {type === NotificationTypeEnum.PERSONAL && (
            <TextInput
              placeHolder={'User ID'}
              formOptions={register('userId', { required: true })}
              errors={errors}
              label={'Used ID'}
            />
          )}
          {type === NotificationTypeEnum.COMPANY && (
            <section className={formSectionStyle}>
              <Separator />
              <select {...register('companyId')}>
                <option value={''}>Nobody</option>
                {companyData?.map((option) => {
                  return <option value={option._id}>{option.name}</option>;
                })}
              </select>
            </section>
          )}
          {type === NotificationTypeEnum.FILTER_LANGUAGE && (
            <section>
              <Separator />
              <select {...register('language')}>
                <option value={''}>Nobody</option>
                {Object.keys(languages).map((key) => (
                  <option value={key}>{languages[key]}</option>
                ))}
              </select>
            </section>
          )}
          {type === NotificationTypeEnum.FILTER_COUNTRY && (
            <section>
              <Separator />
              <select {...register('country')}>
                <option value={''}>Nobody</option>
                {Object.keys(countries).map((key) => (
                  <option value={countries[key]}>{countries[key]}</option>
                ))}
              </select>
            </section>
          )}
        </section>

        <section className={formSectionStyle}>
          <h2>Navigate To</h2>
          <Separator />
          <select {...register('navigateTo')}>
            <option value="">Don't navigate</option>
            {[...navigationScreenValues, ...externalNavigationScreenValues].map(
              (option) => {
                return <option value={option.value}>{option.label}</option>;
              },
            )}
          </select>
        </section>

        <section className={formSectionStyle}>
          <h2>Summary</h2>
          <Separator />
          <p>Title: {title}</p>
          <p>Message: {message}</p>
          <p>Identifier: {name}</p>

          {isScheduled && (
            <>
              <p>
                Date: {scheduledAtDate && formatDateAsString(scheduledAtDate)}
              </p>
              <p>Time: {scheduledAtTime}</p>
            </>
          )}
          {type === NotificationTypeEnum.FILTER_LOCATION && (
            <>
              <p>Included boroughs: {includedBoroughs}</p>
            </>
          )}
          {type === NotificationTypeEnum.PERSONAL && (
            <>
              <p>Personal notification for: {userId}</p>
            </>
          )}
        </section>
        <section className={actionButtonsContainerStyle}>
          <Button
            title={'Close'}
            onClick={closeClickHandler}
            style={{ backgroundColor: Colors.RED }}
          />
          <SubmitButton title={'Create'} disabled={isSending} />
        </section>
      </form>
    </Modal>
  );
};

const formSectionStyle = css`
  margin-bottom: 2rem;
`;

const actionButtonsContainerStyle = css`
  & > * {
    margin-right: 0.5rem;
  }
`;

const formStyle = css`
  position: relative;
`;

const createNotificationModalStyle = {
  content: {
    backgroundColor: Colors.LIGHT_BLUE,
    top: '30%',
    left: '50%',
    right: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -30%)',
    padding: '2rem',
    borderRadius: '1rem',
  },
};
