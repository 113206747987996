import axios from 'axios';
import { configuration } from '../configuration/configuration';
import { userStore } from '../data/stores/user-store';
import {
  ICancelNotificationParams,
  ICreateMessagingCardParams,
  ICreateNotificationParams,
  IDeleteMessagingCardParams,
  ILoginParams,
  IPaginationParams,
  IUpdateMessagingCardParams,
  IUpdateProductParams,
  IUpdateUserParams,
} from '../data/types/input-params-types';
import {
  IAnalytics,
  IMessagingCard,
  INotification,
  IProduct,
  IReportedAccount,
  ITaskSchedulerStatus,
  IUser,
} from '../data/types/server-types';
import { showToast, ToastTypeEnum } from '../utils/toast';

export const axiosInstance = axios.create({
  baseURL: configuration.BACKEND_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response: any) => {
    if (response.data.error) {
      return Promise.reject(response);
    }
    return response;
  },
  (err) => {
    const networkError = !!err.isAxiosError && !err.response;
    if (networkError) {
      // TODO: Show a network error overlay
      return null;
    }
    const { status } = err.response;

    if (status === 401) {
      showToast(ToastTypeEnum.WARNING, 'Authorisation failed, please sign in!');
      userStore.reset();
    }

    if (status === 409) {
      showToast(
        ToastTypeEnum.ERROR,
        'Unique constraint violated! Aborting action.',
      );
    }

    if (status === 500) {
      showToast(ToastTypeEnum.ERROR, 'Something went wrong!');
    }

    return Promise.reject(err);
  },
);

class HTTP {
  getMe = async (): Promise<any> => {
    const { data } = await axiosInstance.get('/v2/users/me');
    return data;
  };
  loginUser = async (params: ILoginParams) => {
    const { data } = await axiosInstance.post<IUser>(`/v2/users/login`, params);
    return data;
  };
  getAnalytics = async () => {
    const { data } = await axiosInstance.get<IAnalytics>('/v2/analytics');
    return data;
  };
  getNotifications = async (
    params: IPaginationParams,
  ): Promise<INotification[]> => {
    const { data } = await axiosInstance.get<INotification[]>(
      '/v2/notifications/all',
      { params },
    );
    return data;
  };
  getProducts = async (params: IPaginationParams): Promise<IProduct[]> => {
    const { data } = await axiosInstance.get<IProduct[]>('/v2/products/admin', {
      params,
    });
    return data;
  };
  updateProduct = async (params: IUpdateProductParams): Promise<IProduct> => {
    const { id, ...rest } = params;
    const { data } = await axiosInstance.put<IProduct>(
      `/v2/products/admin/${id}`,
      { ...rest },
    );
    return data;
  };
  getUsers = async (params: IPaginationParams): Promise<IUser[]> => {
    const { data } = await axiosInstance.get<IUser[]>('/v2/users/admin', {
      params,
    });
    return data;
  };
  updateUser = async (params: IUpdateUserParams): Promise<IUser> => {
    const { data } = await axiosInstance.put<IUser>(
      `/v2/users/admin/${params.id}`,
      { params },
    );
    return data;
  };
  deleteUser = async (params: IUpdateUserParams): Promise<IUser> => {
    const { data } = await axiosInstance.delete<IUser>(
      `/v2/users/admin/${params.id}`,
      { params },
    );
    return data;
  };
  getMessagingCards = async (
    params: IPaginationParams,
  ): Promise<IMessagingCard[]> => {
    const { data } = await axiosInstance.get<IMessagingCard[]>(
      '/v2/messaging-cards',
      { params },
    );
    return data;
  };
  getTaskSchedulerStatus = async (): Promise<ITaskSchedulerStatus> => {
    const { data } = await axiosInstance.get<ITaskSchedulerStatus>(
      '/v2/notifications/task-scheduler/status',
    );
    return data;
  };
  createNotification = async (params: ICreateNotificationParams) => {
    console.log('params: ', params);
    const { data } = await axiosInstance.post<INotification>(
      `/v2/notifications`,
      params,
    );
    return data;
  };
  createMessagingCard = async (params: ICreateMessagingCardParams) => {
    const { data } = await axiosInstance.post<IMessagingCard>(
      `/v2/messaging-cards`,
      params,
    );
    return data;
  };
  updateMessagingCard = async (params: IUpdateMessagingCardParams) => {
    const { data } = await axiosInstance.put<IMessagingCard>(
      `/v2/messaging-cards/${params.id}`,
      params,
    );
    return data;
  };
  deleteMessagingCard = async (params: IDeleteMessagingCardParams) => {
    const { data } = await axiosInstance.delete<IMessagingCard>(
      `/v2/messaging-cards/${params.id}`,
    );
    return data;
  };
  rescheduleExistingNotifications = async () => {
    const { data } = await axiosInstance.post<IMessagingCard>(
      `/v2/notifications/reschedule`,
    );
    return data;
  };
  cancelNotification = async (params: ICancelNotificationParams) => {
    const { data } = await axiosInstance.put<INotification>(
      `/v2/notifications/cancel/${params.id}`,
    );
    return data;
  };
  cancelAllNotifications = async () => {
    const { data } = await axiosInstance.put<any>(
      `/v2/notifications/cancel-all`,
    );
    return data;
  };

  getCompanies = async () => {
    const { data } = await axiosInstance.get<any>(`/v2/companies/get-all`);
    return data;
  };

  sendDeletionEmail = async (email: string): Promise<IUser> => {
    const { data } = await axiosInstance.post<IUser>(
      `/v2/users/actions/delete-account-confirmation`,
      {
        email: email,
      },
    );
    return data;
  };

  getReportedUsers = async (
    params: IPaginationParams,
  ): Promise<IReportedAccount[]> => {
    const { data } = await axiosInstance.get<IReportedAccount[]>(
      '/v2/reported-accounts/get-all',
      { params },
    );
    return data;
  };

  freezeUnfreezeUser = async (userId: string): Promise<IUser> => {
    const { data } = await axiosInstance.post<IUser>(
      `/v2/reported-accounts/freeze-account/${userId}`,
    );
    return data;
  };

  markUserAsDone = async (reportId: string): Promise<IUser> => {
    const { data } = await axiosInstance.post<IUser>(
      `/v2/reported-accounts/mark-as-done/${reportId}`,
    );
    return data;
  };
}

export const http = new HTTP();
