import { toast } from 'react-toastify';

export enum ToastTypeEnum {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS'
}

const toastVariants = {
  ERROR: toast.error,
  WARNING: toast.warning,
  SUCCESS: toast.success
}

export const showToast = (type: ToastTypeEnum, text: string) => {
  const toast = toastVariants[type]
  return toast(text, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}
