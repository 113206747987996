import { css } from '@emotion/css';
import { Colors } from '../styles/colors';

export const Separator = () => {
  return <div className={separatorStyle} />;
};

const separatorStyle = css`
  width: 100%;
  height: 1px;
  background-color: ${Colors.GRAY};
  margin-bottom: 2rem;
`;
