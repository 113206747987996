import { UseMutateAsyncFunction, useMutation, useQuery, UseQueryResult } from "react-query";
import { ICreateMessagingCardParams, IDeleteMessagingCardParams, IPaginationParams, IUpdateMessagingCardParams } from "../../data/types/input-params-types";
import { IMessagingCard } from "../../data/types/server-types";
import { http } from "../http";
import { CacheKeys } from "./configuration";

export function useMessagingCards(params: IPaginationParams): UseQueryResult<IMessagingCard[], any> {
  return useQuery<IMessagingCard[], any, IMessagingCard[]>(
    [CacheKeys.MESSAGING_CARDS, params.skip],
    () => http.getMessagingCards(params),
  ) 
}

export function useCreateMessagingCard(): UseMutateAsyncFunction<IMessagingCard, any, ICreateMessagingCardParams> {
  const { mutateAsync } = useMutation<IMessagingCard, any, ICreateMessagingCardParams>(http.createMessagingCard, {
    onError: error => {
      throw error
    },
  })
  return mutateAsync
}

export function useUpdateMessagingCard(): UseMutateAsyncFunction<IMessagingCard, any, IUpdateMessagingCardParams> {
  const { mutateAsync } = useMutation<IMessagingCard, any, IUpdateMessagingCardParams>(http.updateMessagingCard, {
    onError: error => {
      throw error
    },
  })
  return mutateAsync
}

export function useDeleteMessagingCard(): UseMutateAsyncFunction<IMessagingCard, any, IDeleteMessagingCardParams> {
  const { mutateAsync } = useMutation<IMessagingCard, any, IDeleteMessagingCardParams>(http.deleteMessagingCard, {
    onError: error => {
      throw error
    },
  })
  return mutateAsync
}