type Languages = {
  [index: string]: string;
  en: string;
  nl: string;
  fr: string;
};

export const languages: Languages = {
  en: 'English (en)',
  nl: 'Dutch (nl)',
  fr: 'French (fr)',
};

type Countries = {
  [index: string]: string;
  uk: string;
  be: string;
  nl: string;
};

export const countries: Countries = {
  uk: 'United Kingdom',
  be: 'Belgium',
  nl: 'Netherlands',
};
