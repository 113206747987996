import { css } from '@emotion/css';
import { useState } from 'react';
import Loader from 'react-loader-spinner';
import { Button } from '../../shared/components/Button';
import { Container } from '../../shared/components/Container';
import { HeroSection } from '../../shared/components/HeroSection';
import { PAGINATION_LIMIT } from '../../shared/data/constants';
import { Colors } from '../../shared/styles/colors';
import { TextInput } from '../../shared/components/TextInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SubmitButton } from '../../shared/components/SubmitButton';
import { useUsers } from '../../shared/services/api/users';
import { UsersTable } from './UsersTable';

type SearchProductData = {
  term: string;
};

export const UsersPage = () => {
  const [skip, setSkip] = useState<number>(0);
  const [term, setTerm] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SearchProductData>();

  const {
    data: users,
    isLoading,
    isError,
  } = useUsers({
    term,
    skip,
    limit: PAGINATION_LIMIT,
  });

  if (isError) {
    return <h1>Something went wrong</h1>;
  }

  if (isLoading || !users) {
    return (
      <div className={loaderStyle}>
        <Loader type="ThreeDots" color={Colors.BLUE} height={80} width={80} />
      </div>
    );
  }

  const onPreviousClickHandler = () => {
    setSkip((skip) => Math.max(skip - PAGINATION_LIMIT, 0));
  };

  const onNextClickHandler = () => {
    if (users.length === 0) {
      setSkip((skip) => Math.max(skip - PAGINATION_LIMIT, 0));
    } else {
      setSkip(skip + PAGINATION_LIMIT);
    }
  };

  const onSubmit: SubmitHandler<SearchProductData> = async (
    data: SearchProductData,
  ) => {
    setSkip(0);
    setTerm(data.term);
  };

  return (
    <>
      <Container>
        <HeroSection
          backgroundColor={Colors.BLUE}
          title={'Users'}
        />
        <form onSubmit={handleSubmit(onSubmit)} className={searchFormStyle}>
          <TextInput
            placeHolder={'Search'}
            formOptions={register('term', { required: false })}
            errors={errors}
          />
          <SubmitButton title={'Search'}/>
        </form>
        <section className={dashboardStyle}>
          {users.length > 0 ? (
            <UsersTable users={users} skip={skip} term={term}/>
          ) : (
            <div>Didn't find what you are looking for? Contact the devs!</div>
          )}
          <div className={paginationContainerStyle}>
            <Button
              title={'<'}
              onClick={onPreviousClickHandler}
              disabled={skip === 0}
            />
            <span className={paginationIndexStyle}>
              {skip / PAGINATION_LIMIT}
            </span>
            <Button
              title={'>'}
              onClick={onNextClickHandler}
              disabled={users.length === 0}
            />
          </div>
        </section>
      </Container>
    </>
  );
};

const searchFormStyle = css`
  margin-left: 2rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  button {
    margin-left: 0.5rem;
  }
`

const dashboardStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-x: scroll;
`;

const paginationContainerStyle = css`
  padding: 2rem;
`;

const paginationIndexStyle = css`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const loaderStyle = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
