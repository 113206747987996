import { css } from '@emotion/css';
import { Button } from '../../shared/components/Button';
import { IMessagingCard } from '../../shared/data/types/server-types';
import { Colors } from '../../shared/styles/colors';
import { formatDateTimeAsString } from '../../shared/utils/format-date';

interface IMessagingCardsTable {
  messagingCards: IMessagingCard[];
  onEdit(messagingCard: IMessagingCard): void;
  onDelete(messagingCardId: string): void;
}

export const MessagingCardsTable = (props: IMessagingCardsTable) => {
  const { messagingCards, onEdit, onDelete } = props;

  return (
    <table className={tableStyle}>
      <th>ID</th>
      <th>Message</th>
      <th>Action</th>
      <th>Created At</th>
      <th>Edit</th>
      <th>Delete</th>
      <tbody>
        {messagingCards.map((messagingCard: IMessagingCard, index: number) => {
          return (
            <tr key={index}>
              <td>{messagingCard._id}</td>
              <td>{messagingCard.message}</td>
              <td>{messagingCard.action}</td>
              <td>{formatDateTimeAsString(messagingCard.createdOn)}</td>
              <td>
                <Button
                  title={'Edit'}
                  onClick={() => onEdit(messagingCard)}
                  style={{
                    backgroundColor: Colors.ORANGE,
                    padding: 5,
                    borderRadius: 0,
                    marginRight: 12,
                  }}
                />
              </td>
              <td>
                <Button
                  title={'Delete'}
                  onClick={() => onDelete(messagingCard._id)}
                  style={{
                    backgroundColor: Colors.RED,
                    padding: 5,
                    borderRadius: 0,
                  }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const tableStyle = css`
  width: 100%;
  margin-top: 1rem;

  th {
    text-align: left;
    border-bottom: 1px solid ${Colors.GRAY};
  }

  tr:nth-of-type(2n) {
    background-color: ${Colors.LIGHT_BLUE};
  }

  td {
    padding: 0.7rem;
    border: none;
  }
`;
