import {
  QueryClient,
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  UseQueryResult,
} from 'react-query';
import {
  ILoginParams,
  IPaginationParams,
  IUpdateProductParams,
  IUpdateUserParams,
} from '../../data/types/input-params-types';
import { IReportedAccount, IUser } from '../../data/types/server-types';
import { http } from '../http';
import { CacheKeys } from './configuration';

export function useMe(): UseQueryResult<IUser, any> {
  return useQuery<IUser, any>(CacheKeys.ME, http.getMe);
}

export function useLoginUser(): UseMutateAsyncFunction<
  IUser,
  any,
  ILoginParams
> {
  const { mutateAsync } = useMutation<IUser, any, ILoginParams>(
    http.loginUser,
    {
      onError: (error) => {
        throw error;
      },
    },
  );
  return mutateAsync;
}

export function useUsers(
  params: IPaginationParams & { term: string },
): UseQueryResult<IUser[], any> {
  return useQuery<IUser[], any, IUser[]>(
    [CacheKeys.USERS, params.skip, params.term],
    () => http.getUsers(params),
  );
}

export function useUpdateUser(): UseMutateAsyncFunction<
  IUser,
  any,
  IUpdateUserParams
> {
  const { mutateAsync } = useMutation<IUser, any, IUpdateUserParams>(
    http.updateUser,
    {
      onError: (error) => {
        throw error;
      },
    },
  );
  return mutateAsync;
}

export function useDeleteUser(): UseMutateAsyncFunction<
  IUser,
  any,
  IUpdateUserParams
> {
  const { mutateAsync } = useMutation<IUser, any, IUpdateUserParams>(
    http.deleteUser,
    {
      onError: (error) => {
        throw error;
      },
    },
  );
  return mutateAsync;
}

export function useSendUserDeletionMail(): UseMutateAsyncFunction<
  IUser,
  any,
  string
> {
  const { mutateAsync } = useMutation<IUser, any, string>(
    http.sendDeletionEmail,
    {
      onError: (error) => {
        throw error;
      },
    },
  );
  return mutateAsync;
}

export function useReportedUsers(
  params: IPaginationParams & { term: string },
): UseQueryResult<IReportedAccount[], any> {
  return useQuery<IReportedAccount[], any, IReportedAccount[]>(
    [CacheKeys.REPORTED_USERS, params.skip, params.term],
    () => http.getReportedUsers(params),
  );
}

export function useFreezeUnfreezeUser(): UseMutateAsyncFunction<
  IUser,
  any,
  string
> {
  const queryClient = new QueryClient();
  const { mutateAsync } = useMutation<IUser, any, string>(
    http.freezeUnfreezeUser,
    {
      onError: (error) => {
        throw error;
      },
      onSuccess: () => {
        queryClient.refetchQueries([CacheKeys.REPORTED_USERS]);
      },
    },
  );
  return mutateAsync;
}
export function useMarkAsDone(): UseMutateAsyncFunction<IUser, any, string> {
  const queryClient = new QueryClient();
  const { mutateAsync } = useMutation<IUser, any, string>(http.markUserAsDone, {
    onError: (error) => {
      throw error;
    },
    onSuccess: () => {
      queryClient.refetchQueries([CacheKeys.REPORTED_USERS]);
    },
  });
  return mutateAsync;
}
