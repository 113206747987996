import { makeAutoObservable } from 'mobx';
import { IUser } from '../types/server-types';
import { makePersistable } from 'mobx-persist-store';

class UserStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'UserStore',
      properties: ['user'],
      storage: localStorage,
    });
  }

  user: string | null = null;

  setUser(user: IUser | null) {
    this.user = JSON.stringify(user);
  }

  getUser(): IUser | null {
    return this.user ? JSON.parse(this.user) : null;
  }

  reset() {
    this.setUser(null);
  }
}

export const userStore = new UserStore();
