import { css } from '@emotion/css';
import { Colors } from '../../shared/styles/colors';

interface IAnalyticsCardProps {
  title: string;
  count: number;
}

export const AnalyticsCard = (props: IAnalyticsCardProps) => {
  const { title, count } = props;
  return (
    <div className={analyticsCardContainerStyle}>
      <h2>{count}</h2>
      <h3>{title}</h3>
    </div>
  );
};

const analyticsCardContainerStyle = css`
  background-color: ${Colors.WHITE};
  color: ${Colors.BLUE};
  box-shadow: 0px 0px 12px ${Colors.SHADOW_COLOR};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 10rem;
  height: 10rem;
  border-radius: 1rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
`;
