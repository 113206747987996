import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  UseQueryResult,
} from 'react-query';
import {
  ICancelNotificationParams,
  ICreateNotificationParams,
  IPaginationParams,
} from '../../data/types/input-params-types';
import { INotification } from '../../data/types/server-types';
import { http } from '../http';
import { CacheKeys } from './configuration';

export function useNotifications(
  params: IPaginationParams,
): UseQueryResult<INotification[], any> {
  return useQuery<INotification[], any, INotification[]>(
    [CacheKeys.NOTIFICATIONS, params.skip],
    () => http.getNotifications(params),
  );
}

export function useCreateNotification(): UseMutateAsyncFunction<
  INotification,
  any,
  ICreateNotificationParams
> {
  const { mutateAsync } = useMutation<
    INotification,
    any,
    ICreateNotificationParams
  >(http.createNotification, {
    onError: (error) => {
      throw error;
    },
  });
  return mutateAsync;
}

export function useCancelNotification(): UseMutateAsyncFunction<
  INotification,
  any,
  ICancelNotificationParams
> {
  const { mutateAsync } = useMutation<
    INotification,
    any,
    ICancelNotificationParams
  >(http.cancelNotification, {
    onError: (error) => {
      throw error;
    },
  });
  return mutateAsync;
}

export function useCancelAllNotifications(): UseMutateAsyncFunction<
  any,
  any,
  any
> {
  const { mutateAsync } = useMutation<any, any, any>(
    http.cancelAllNotifications,
    {
      onError: (error) => {
        throw error;
      },
    },
  );
  return mutateAsync;
}

export function useRescheduleExistingNotifications(): UseMutateAsyncFunction<
  any,
  any,
  any
> {
  const { mutateAsync } = useMutation<any, any, any>(
    http.rescheduleExistingNotifications,
    {
      onError: (error) => {
        throw error;
      },
    },
  );
  return mutateAsync;
}
