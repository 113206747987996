import { css } from '@emotion/css';
import { useState } from 'react';
import Loader from 'react-loader-spinner';
import { useQueryClient } from 'react-query';
import { Button } from '../../shared/components/Button';
import { Container } from '../../shared/components/Container';
import { HeroSection } from '../../shared/components/HeroSection';
import { PAGINATION_LIMIT } from '../../shared/data/constants';
import { IMessagingCard } from '../../shared/data/types/server-types';
import { CacheKeys } from '../../shared/services/api/configuration';
import {
  useDeleteMessagingCard,
  useMessagingCards,
} from '../../shared/services/api/messaging-cards';
import { Colors } from '../../shared/styles/colors';
import { CreateMessagingCardModal } from './CreateMessagingCardModal';
import { EditMessagingCardModal } from './EditMessagingCardModal';
import { MessagingCardsTable } from './MessagingCardsTable';

export const MessagingCardsPage = () => {
  const [skip, setSkip] = useState<number>(0);
  const [initialModalValue, setInitialModalValue] = useState<IMessagingCard>();
  const [editMessagingCardModalVisible, setEditMessagingCardModalVisible] =
    useState<boolean>(false);
  const [createMessagingCardModalVisible, setCreateMessagingCardModalVisible] =
    useState<boolean>(false);
  const queryClient = useQueryClient();
  const deleteMessagingCard = useDeleteMessagingCard();

  const {
    data: messagingCards,
    isLoading,
    isError,
  } = useMessagingCards({
    skip,
    limit: PAGINATION_LIMIT,
  });

  if (isError) {
    return <h1>Something went wrong</h1>;
  }

  if (isLoading || !messagingCards) {
    return (
      <div className={loaderStyle}>
        <Loader type="ThreeDots" color={Colors.BLUE} height={80} width={80} />
      </div>
    );
  }

  const onPreviousClickHandler = () => {
    setSkip((skip) => Math.max(skip - PAGINATION_LIMIT, 0));
  };

  const onNextClickHandler = () => {
    if (messagingCards.length === 0) {
      setSkip((skip) => Math.max(skip - PAGINATION_LIMIT, 0));
    } else {
      setSkip(skip + PAGINATION_LIMIT);
    }
  };

  const onNewClickHandler = () => {
    setCreateMessagingCardModalVisible(true);
  };

  const onMessagingCardEdit = (messageCard: IMessagingCard) => {
    setInitialModalValue(messageCard);
    setEditMessagingCardModalVisible(true);
  };

  const onMessagingCardDelete = async (id: string) => {
    await deleteMessagingCard({ id });
    queryClient.refetchQueries(CacheKeys.MESSAGING_CARDS);
  };

  return (
    <>
      <Container>
        <HeroSection
          backgroundColor={Colors.ORANGE}
          title={'Messaging Cards'}
        />
        <div className={actionButtonsContainerStyle}>
          <Button title={'New Messaging Card'} onClick={onNewClickHandler} />
        </div>
        <section className={dashboardStyle}>
          {messagingCards.length > 0 ? (
            <MessagingCardsTable
              messagingCards={messagingCards}
              onEdit={onMessagingCardEdit}
              onDelete={onMessagingCardDelete}
            />
          ) : (
            <div>Didn't find what you are looking for? Contact the devs!</div>
          )}
          <div className={paginationContainerStyle}>
            <Button
              title={'<'}
              onClick={onPreviousClickHandler}
              disabled={skip === 0}
            />
            <span className={paginationIndexStyle}>
              {skip / PAGINATION_LIMIT}
            </span>
            <Button
              title={'>'}
              onClick={onNextClickHandler}
              disabled={messagingCards.length === 0}
            />
          </div>
        </section>
      </Container>
      <CreateMessagingCardModal
        isOpen={createMessagingCardModalVisible}
        setIsOpen={setCreateMessagingCardModalVisible}
      />
      <EditMessagingCardModal
        initialValues={initialModalValue}
        isOpen={editMessagingCardModalVisible}
        setIsOpen={setEditMessagingCardModalVisible}
      />
    </>
  );
};

const actionButtonsContainerStyle = css`
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-right: 2rem;

  & > * {
    margin-left: 0.5rem;
  }
`;

const dashboardStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-x: scroll;
`;

const paginationContainerStyle = css`
  padding: 2rem;
`;

const paginationIndexStyle = css`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const loaderStyle = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
