import { injectGlobal } from '@emotion/css';
import { AppRouter } from './routers/AppRouter';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Colors } from './shared/styles/colors';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import React from 'react';

const queryClient = new QueryClient();
Modal.setAppElement('#root');

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppRouter />
      <ToastContainer />
    </QueryClientProvider>
  );
};

injectGlobal`
 @font-face {
    font-family: 'Roboto';
    font-weight: normal;
    src: local('Roboto'), url(./fonts/roboto/Roboto-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'MPlus';
    font-weight: normal;
    src: local('MPlus'), url(./fonts/mplus/MPLUSRounded1c-Regular.ttf) format('truetype');
  }

  * {
    font-family: Roboto;
    margin: 0px;
    padding: 0px;
    color: ${Colors.BLACK};
  }

  html, body {
    background-color: ${Colors.LIGHT_BLUE};
    font-size: 13px;
  }

  h1, h2, h3, h4 {
    font-family: 'MPlus';
  }
`;
