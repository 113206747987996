export enum MessagingCardActions {
  SHARE = 'SHARE',
  LIST = 'LIST',
  SEARCH = 'SEARCH',
}

export enum NotificationTypeEnum {
  SEGMENT = 'SEGMENT',
  PERSONAL = 'PERSONAL',
  FILTER_LOCATION = 'FILTER_LOCATION',
  COMPANY = 'COMPANY',
  FILTER_LANGUAGE = 'FILTER_LANGUAGE',
  FILTER_COUNTRY = 'FILTER_COUNTRY',
}

export enum NotificationSubtypeEnum {
  ADMIN_MESSAGE = 'ADMIN_MESSAGE',
  MEMBERSHIP = 'MEMBERSHIP',
  LIKE_NOTIFICATION = 'LIKE_NOTIFICATION',
  WEEKLY_LIKES_REPORT = 'WEEKLY_LIKES_REPORT',
}

export enum NotificationStatusEnum {
  SENDING = 'SENDING',
  SENT = 'SENT',
  FAILED = 'FAILED',
  SCHEDULED = 'SCHEDULED',
}

export enum NavigationScreenEnum {
  MEMBERSHIPSCREEN = 'MembershipScreen',
}
export enum ExternalNavigationEnum {
  MISS_WASTE_LESS_EBOOK = 'https://youngplanet-public-assets.s3.eu-west-2.amazonaws.com/downloads/ecover-mr-men/Little+Miss+Waste+Less+by+Adam+Hargreaves.epub',
}
