import { css, cx } from '@emotion/css';
import { Colors } from '../styles/colors';

interface ISubmitButtonProps {
  title: string;
  disabled?: boolean;
  style?: any;
}

export const SubmitButton = (props: ISubmitButtonProps) => {
  const { title, disabled, style } = props;
  return (
    <button
      className={cx(
        { [submitButtonStyle]: !disabled },
        { [disabledSubmitButtonStyle]: disabled },
      )}
      disabled={disabled}
      style={style}
      type={'submit'}
    >
      {title}
    </button>
  );
};

const submitButtonStyle = css`
  background-color: ${Colors.ORANGE};
  color: ${Colors.WHITE};
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.5rem;
  border: none;

  &:active {
    opacity: 0.7;
  }
`;

const disabledSubmitButtonStyle = css`
  background-color: ${Colors.GRAY};
  color: ${Colors.WHITE};
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.5rem;
  border: none;
`;
