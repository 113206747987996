import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SubmitButton } from '../../shared/components/SubmitButton';
import { TextInput } from '../../shared/components/TextInput';
import {
  useFreezeUnfreezeUser,
  useMarkAsDone,
  useReportedUsers,
} from '../../shared/services/api/users';
import { ReportedUsersTable } from './ReportedUsersTable';
import { css } from '@emotion/css';
import { PAGINATION_LIMIT } from '../../shared/data/constants';
import { Container } from '../../shared/components/Container';
import { Colors } from '../../shared/styles/colors';
import { HeroSection } from '../../shared/components/HeroSection';

type SearchProductData = {
  term: string;
};

export const ReportUsers = () => {
  const [skip, setSkip] = useState<number>(0);
  const [term, setTerm] = useState<string>('');
  const {
    data: reportedUsers,
    isLoading,
    isError,
    refetch,
  } = useReportedUsers({ term, skip, limit: PAGINATION_LIMIT });
  const freezeUnfreezeUser = useFreezeUnfreezeUser();
  const markAsDone = useMarkAsDone();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SearchProductData>();

  const freezeUnfrezeHandle = async (userId: string) => {
    await freezeUnfreezeUser(userId);
    refetch();
  };

  const markAsDoneUser = async (reportId: string) => {
    await markAsDone(reportId);
    refetch();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error</div>;
  }

  const onSubmit: SubmitHandler<SearchProductData> = async (
    data: SearchProductData,
  ) => {
    setSkip(0);
    setTerm(data.term);
  };

  return (
    <>
      <Container>
        <HeroSection backgroundColor={Colors.RED} title={'Reported users'} />
        <div className={reportedUsersPageContainer}>
          <form onSubmit={handleSubmit(onSubmit)} className={searchFormStyle}>
            <TextInput
              placeHolder={'Search'}
              formOptions={register('term', { required: false })}
              errors={errors}
            />
            <SubmitButton title={'Search'} />
          </form>
          <section className={reportedUsersTable}>
            <ReportedUsersTable
              users={reportedUsers}
              skip={skip}
              term={term}
              freezeUnfreezeUser={freezeUnfrezeHandle}
              markAsDoneUser={markAsDoneUser}
            />
          </section>
        </div>
      </Container>
    </>
  );
};

const searchFormStyle = css`
  margin-left: 2rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    margin-left: 0.5rem;
  }
`;

const reportedUsersPageContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const reportedUsersTable = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  overflow-x: scroll;
`;
