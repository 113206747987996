import { HTMLInputTypeAttribute } from 'react';
import { css } from '@emotion/css';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types';
import { Colors } from '../styles/colors';

type ITextInputProps = {
  placeHolder: string;
  type?: HTMLInputTypeAttribute;
  defaultValue?: any;
  formOptions: UseFormRegisterReturn;
  errors?: any;
  label?: string;
};

export const TextInput = (props: ITextInputProps) => {
  const {
    defaultValue,
    placeHolder,
    type = 'text',
    formOptions,
    label,
    errors,
  } = props;

  return (
    <div className={textInputContainerStyle}>
      {label && <h3 className={textInputLabelStyle}>{label}</h3>}
      <input
        className={textInputStyle}
        placeholder={placeHolder}
        defaultValue={defaultValue}
        type={type}
        {...formOptions}
      />
      {/* {!isEmpty(errors) && <span>{errors[formOptions.name].type}</span>} */}
    </div>
  );
};

const textInputContainerStyle = css`
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

const textInputLabelStyle = css`
  margin-bottom: 0.5rem;
`;

const textInputStyle = css`
  width: 100%;
  height: 2rem;
  border-radius: 1rem;
  padding: 1rem;
  border: none;
  box-sizing: border-box;
  border: 1px ${Colors.GRAY} solid;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }
`;
