import React from 'react';
import { css } from '@emotion/css';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Colors } from '../../shared/styles/colors';
import { TextInput } from '../../shared/components/TextInput';
import { useLoginUser } from '../../shared/services/api/users';
import { userStore } from '../../shared/data/stores/user-store';
import { useHistory } from 'react-router';
import { Routes } from '../../routers/routes';
import { YoungPlanetLogo } from '../../shared/assets/icons';
import { SubmitButton } from '../../shared/components/SubmitButton';
import 'react-toastify/dist/ReactToastify.css';
import { showToast, ToastTypeEnum } from '../../shared/utils/toast';
import { Button } from '../../shared/components/Button';

type SignInFormData = {
  email: string;
  password: string;
};

export const SignInPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormData>();
  const loginUser = useLoginUser();

  const history = useHistory();

  const onSubmit: SubmitHandler<SignInFormData> = async (
    data: SignInFormData,
  ) => {
    try {
      const user = await loginUser({
        email: data.email.trim().toLowerCase(),
        password: data.password.trim(),
        admin: true,
      });
      userStore.setUser(user);
      history.push(Routes.DASHBOARD);
    } catch (e) {
      showToast(ToastTypeEnum.ERROR, 'Wrong credentials.');
    }
  };

  return (
    <section className={signUpFormStyle}>
      <YoungPlanetLogo height={300} width={300} />
      <h2>Welcome,</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          placeHolder={'Enter your email'}
          formOptions={register('email', { required: true })}
          errors={errors}
        />
        <TextInput
          placeHolder={'Enter your password'}
          formOptions={register('password', { required: true })}
          errors={errors}
          type={'password'}
        />
        <SubmitButton
          title={'Sign in'}
          style={{ width: '100%', marginBottom: '10px' }}
        />
      </form>
    </section>
  );
};

const signUpFormStyle = css`
  box-shadow: 0px 0px 12px ${Colors.SHADOW_COLOR};
  background-color: ${Colors.DARK_BLUE};
  padding: 3rem;
  border-radius: 2rem;
  width: 20rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  h2 {
    color: ${Colors.WHITE};
    margin-bottom: 1rem;
  }

  input {
    margin-bottom: 0.5rem;
  }
`;
