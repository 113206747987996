import React, { useState } from 'react';
import { css } from '@emotion/css';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Colors } from '../../shared/styles/colors';
import { TextInput } from '../../shared/components/TextInput';
import { useSendUserDeletionMail } from '../../shared/services/api/users';
import { useHistory } from 'react-router';
import { SubmitButton } from '../../shared/components/SubmitButton';
import 'react-toastify/dist/ReactToastify.css';
import { showToast, ToastTypeEnum } from '../../shared/utils/toast';
import { Button } from '../../shared/components/Button';

type DeleteUserFormData = {
  email: string;
};

export const DeleteAccountPage = () => {
  const [didUserSubmitDelete, setDidUserSubmitDelete] =
    useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DeleteUserFormData>();
  const history = useHistory();
  const sendUserDeletionMail = useSendUserDeletionMail();

  const onSubmit: SubmitHandler<DeleteUserFormData> = async (
    data: DeleteUserFormData,
  ) => {
    try {
      setDidUserSubmitDelete(true);
      sendUserDeletionMail(data.email);
    } catch (e) {
      showToast(ToastTypeEnum.ERROR, 'Something went wrong.');
    }
  };

  return (
    <section className={signUpFormStyle}>
      {!didUserSubmitDelete ? (
        <>
          <h2>
            Confirm the email you have used for your YoungPlanet account. We
            will send an email for you to confirm .
          </h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              placeHolder={'Enter your email'}
              formOptions={register('email', { required: true })}
              errors={errors}
            />
            <Button
              title={'Back'}
              style={{ width: '100%' }}
              onClick={() => history.goBack()}
            />
            <SubmitButton
              title={'Delete Account'}
              style={{ width: '100%', marginTop: '10px' }}
            />
          </form>
        </>
      ) : (
        <>
          <h2>Please check your inbox to confirm your account deletion.</h2>
          <h2>Thank you for using YoungPlanet</h2>
        </>
      )}
    </section>
  );
};

const signUpFormStyle = css`
  box-shadow: 0px 0px 12px ${Colors.SHADOW_COLOR};
  background-color: ${Colors.DARK_BLUE};
  padding: 3rem;
  border-radius: 2rem;
  width: 20rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  h2 {
    color: ${Colors.WHITE};
    margin-bottom: 1rem;
  }

  input {
    margin-bottom: 0.5rem;
  }
`;
