import { useQuery, UseQueryResult } from 'react-query';
import { IPaginationParams } from '../../data/types/input-params-types';
import { ICompany } from '../../data/types/server-types';
import { http } from '../http';
import { CacheKeys } from './configuration';

export function useCompanies(): UseQueryResult<ICompany[], any> {
  return useQuery<ICompany[], any, ICompany[]>([CacheKeys.COMPANIES], () =>
    http.getCompanies(),
  );
}
