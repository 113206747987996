import {
  IPaginationParams,
  IUpdateMessagingCardParams,
  IUpdateProductParams,
} from '../../data/types/input-params-types';
import { UseMutateAsyncFunction, useMutation, useQuery, UseQueryResult } from 'react-query';
import { IMessagingCard, IProduct } from '../../data/types/server-types';
import { CacheKeys } from './configuration';
import { http } from '../http';

export function useProducts(params: IPaginationParams & { term: string }): UseQueryResult<IProduct[], any> {
  return useQuery<IProduct[], any, IProduct[]>(
    [CacheKeys.PRODUCTS, params.skip, params.term],
    () => http.getProducts(params),
  )
}

export function useUpdateProduct(): UseMutateAsyncFunction<IProduct, any, IUpdateProductParams> {
  const { mutateAsync } = useMutation<IProduct, any, IUpdateProductParams>(http.updateProduct, {
    onError: error => {
      throw error
    },
  })
  return mutateAsync
}
