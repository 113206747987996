import { css } from '@emotion/css';
import { Colors } from '../styles/colors';

interface IContainerProps {
  children: React.ReactNode;
}

export const Container = (props: IContainerProps) => {
  return <div className={containerStyle}>{props.children}</div>;
};

const containerStyle = css`
  background-color: ${Colors.WHITE};
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`;
