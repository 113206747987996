import { css } from '@emotion/css';
import { useHistory } from 'react-router';
import { Routes } from '../../routers/routes';
import { Container } from '../../shared/components/Container';
import { HeroSection } from '../../shared/components/HeroSection';
import { Colors } from '../../shared/styles/colors';

export const NotFoundPage = () => {
  const history = useHistory();

  return (
    <Container>
      <HeroSection
        backgroundColor={Colors.DARK_BLUE}
        title={'404 Page Not Found'}
      />
    </Container>
  );
};
