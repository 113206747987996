import { css, cx } from '@emotion/css';
import { Colors } from '../styles/colors';

interface IButtonProps {
  title: string;
  onClick(): void;
  disabled?: boolean;
  style?: any;
  pointer?: boolean;
}

export const Button = (props: IButtonProps) => {
  const { title, onClick, disabled, style, pointer } = props;
  const onHoverPointer = css`
    &:hover {
      cursor: pointer;
    }
  `;
  return (
    <button
      className={cx(
        { [buttonStyle]: !disabled },
        { [disabledButtonStyle]: disabled },
        { [onHoverPointer]: pointer },
      )}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

const buttonStyle = css`
  background-color: ${Colors.ORANGE};
  color: ${Colors.WHITE};
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.5rem;
  border: none;

  &:active {
    opacity: 0.7;
  }
`;

const disabledButtonStyle = css`
  background-color: ${Colors.GRAY};
  color: ${Colors.WHITE};
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.5rem;
  border: none;
`;
