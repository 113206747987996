import { css, cx } from '@emotion/css';
import { Colors } from '../styles/colors';

interface IHeroSectionProps {
  backgroundColor: Colors;
  title: string;
}

export const HeroSection = (props: IHeroSectionProps) => {
  const { backgroundColor, title } = props;
  return (
    <section className={heroSectionContainerStyle} style={{ backgroundColor }}>
      <h1>{title}</h1>
    </section>
  );
};

const heroSectionContainerStyle = css`
  height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    color: ${Colors.WHITE};
    font-size: 3rem;
  }
`;
