import { css } from '@emotion/css';
import { useQueryClient } from 'react-query';
import { Button } from '../../shared/components/Button';
import { IUser } from '../../shared/data/types/server-types';
import { Colors } from '../../shared/styles/colors';
import { formatDateTimeAsString } from '../../shared/utils/format-date';
import { useDeleteUser, useUpdateUser } from '../../shared/services/api/users';
import { adminFrozenLabels } from '../../shared/data/constants';
import { CacheKeys } from '../../shared/services/api/configuration';

interface IUsersTableProps {
  users: IUser[];
  skip: number;
  term: string;
}

export const UsersTable = (props: IUsersTableProps) => {
  const { users, skip, term } = props;
  const queryClient = useQueryClient();
  const updateUser = useUpdateUser();
  const deleteUser = useDeleteUser();

  const onFreezeNoStatus = async (id: string) => {
    await updateUser({
      id,
      frozenByAdmin: 0,
    });
    await queryClient.refetchQueries([CacheKeys.USERS, skip, term]);
  };

  const onFreezePermanently = async (id: string) => {
    await updateUser({
      id,
      frozenByAdmin: 1,
    });
    await queryClient.refetchQueries([CacheKeys.USERS, skip, term]);
  };

  const onUnfreezePermanently = async (id: string) => {
    await updateUser({
      id,
      frozenByAdmin: 2,
    });
    await queryClient.refetchQueries([CacheKeys.USERS, skip, term]);
  };

  const onDelete = async (id: string) => {
    await deleteUser({
      id
    });
    await queryClient.refetchQueries([CacheKeys.USERS, skip, term]);
  };

  return (
    <table className={tableStyle}>
      <th>ID</th>
      <th>Name</th>
      <th>Surname</th>
      <th>Email</th>
      <th>FB ID</th>
      <th>Postcode</th>
      <th>Verified</th>
      <th>Items Count</th>
      <th>Borough</th>
      <th>Registered On</th>
      <th>Frozen</th>
      <th>Frozen by admin</th>
      <th>Set Freeze</th>
      <th>Set Freeze</th>
      <th>Set Freeze</th>
      <th>Delete</th>
      <tbody>
        {users.map((user: IUser, index: number) => {
          return (
            <tr key={index}>
              <td>{user._id}</td>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.email}</td>
              <td>{user.facebookId}</td>
              <td>{user.postCode}</td>
              <td>{user.verificationStatus ? 'Yes' : 'No'}</td>
              <td>{user.count}</td>
              <td>{user.borough}</td>
              <td>{formatDateTimeAsString(user.createdOn)}</td>
              <td>{user.frozen ? 'Yes' : 'No'}</td>
              <td>{adminFrozenLabels[user.frozenByAdmin]}</td>
              <td>
                <Button
                  title={'No Action Taken'}
                  onClick={() => onFreezeNoStatus(user._id)}
                  style={{
                    backgroundColor: Colors.ORANGE,
                    padding: 5,
                    borderRadius: 0,
                  }}
                />
              </td>
              <td>
                <Button
                  title={'Freeze Permanently'}
                  onClick={() => onFreezePermanently(user._id)}
                  style={{
                    backgroundColor: Colors.BLUE,
                    padding: 5,
                    borderRadius: 0,
                  }}
                />
              </td>
              <td>
                <Button
                  title={'Unfreeze Permanently'}
                  onClick={() => onUnfreezePermanently(user._id)}
                  style={{
                    backgroundColor: Colors.GREEN,
                    padding: 5,
                    borderRadius: 0,
                  }}
                />
              </td>
              <td>
                <Button
                  title={'Delete'}
                  onClick={() => onDelete(user._id)}
                  style={{
                    backgroundColor: Colors.RED,
                    padding: 5,
                    borderRadius: 0,
                  }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const tableStyle = css`
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
  display: block;
  overflow-x: auto;
  white-space: nowrap;

  th {
    text-align: left;
    border-bottom: 1px solid ${Colors.GRAY};
  }

  tr:nth-of-type(2n) {
    background-color: ${Colors.LIGHT_BLUE};
  }

  td {
    padding: 0.7rem;
    border: none;
  }
`;
