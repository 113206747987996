import { css } from '@emotion/css';
import ThreeDots from 'react-loader-spinner';
import { Colors } from '../styles/colors';

interface ILoaderProps {
  color?: Colors;
}

export const Loader = (props: ILoaderProps) => {
  const { color = Colors.BLUE } = props;
  return (
    <div className={loaderStyle}>
      <ThreeDots type="ThreeDots" color={color} height={80} width={80} />
    </div>
  );
};

const loaderStyle = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
