export enum Routes {
  DASHBOARD = '/',
  SIGN_IN = '/sign-in',
  NOTIFICATIONS = '/notifications',
  MESSAGING_CARDS = '/messaging-cards',
  USERS = '/users',
  PRODUCTS = '/products',
  DELETE_ACCOUNT = '/delete-account',
  REPORTED_USERS = '/reported-users',
}
