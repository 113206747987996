import { css } from '@emotion/css';
import { useQueryClient } from 'react-query';
import { Button } from '../../shared/components/Button';
import { IReportedAccount, IUser } from '../../shared/data/types/server-types';
import { Colors } from '../../shared/styles/colors';
import { formatDateTimeAsString } from '../../shared/utils/format-date';
import {
  useDeleteUser,
  useFreezeUnfreezeUser,
  useUpdateUser,
} from '../../shared/services/api/users';
import { adminFrozenLabels } from '../../shared/data/constants';
import { CacheKeys } from '../../shared/services/api/configuration';
import { useState } from 'react';

interface IUsersTableProps {
  users: IReportedAccount[] | any;
  skip: number;
  term: string;
  freezeUnfreezeUser(userId: string): void;
  markAsDoneUser(userId: string): void;
}

export const ReportedUsersTable = (props: IUsersTableProps) => {
  const { users, skip, term, freezeUnfreezeUser, markAsDoneUser } = props;
  const [showMarkedAsDone, setShowAsMarkedDone] = useState(false);
  const queryClient = useQueryClient();

  const filteredUsers = showMarkedAsDone
    ? users.filter((user: IReportedAccount) => !user.markedAsDone)
    : users;

  return (
    <div className={pageContainer}>
      <Button
        title={!showMarkedAsDone ? 'Show unprocessed' : 'Show all'}
        onClick={() => setShowAsMarkedDone(!showMarkedAsDone)}
      />
      <table className={tableStyle}>
        <th>ID</th>
        <th>Name</th>
        <th>Surname</th>
        <th>Email</th>
        <th>Report reason</th>
        <th>Reported by</th>
        <th>Reported item</th>
        <th></th>
        <th></th>

        <tbody>
          {filteredUsers?.map(
            (userReportInfo: IReportedAccount, index: number) => {
              return (
                <tr key={index}>
                  <td>{userReportInfo.reportedUserField._id}</td>
                  <td>{userReportInfo.reportedUserField.firstName}</td>
                  <td>{userReportInfo.reportedUserField.lastName}</td>
                  <td>{userReportInfo.reportedUserField.email}</td>
                  <td>{userReportInfo.reportReason}</td>
                  <td>{userReportInfo.reportedBy}</td>
                  <td>{userReportInfo.reportedItem}</td>
                  <td>
                    <Button
                      title={userReportInfo.frozen ? 'Unfreeze' : 'Freeze'}
                      onClick={() =>
                        freezeUnfreezeUser(userReportInfo.reportedUserField._id)
                      }
                      pointer
                    />
                  </td>
                  <td>
                    <Button
                      title={
                        userReportInfo.markedAsDone ? 'Done' : 'Mark as done'
                      }
                      onClick={() => markAsDoneUser(userReportInfo._id)}
                      disabled={userReportInfo.markedAsDone}
                      pointer
                    />
                  </td>
                </tr>
              );
            },
          )}
        </tbody>
      </table>
    </div>
  );
};

const tableStyle = css`
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
  display: block;
  overflow-x: auto;
  white-space: nowrap;

  th {
    text-align: left;
    padding: 0.7rem;
    border-bottom: 1px solid ${Colors.GRAY};
  }

  tr:nth-of-type(2n) {
    background-color: ${Colors.LIGHT_BLUE};
  }

  td {
    padding: 0.7rem;
  }
`;

const pageContainer = css`
  height: 100vh;
  align-self: flex-start;
  overflow-x: scroll;
`;
