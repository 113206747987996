export enum CacheKeys {
  ME = 'me',
  ANALYTICS = 'analytics',
  TASK_SCHEDULER_STATUS = 'taskSchedulerStatus',
  NOTIFICATIONS = 'notifications',
  MESSAGING_CARDS = 'messagingCards',
  PRODUCTS = 'products',
  USERS = 'users',
  COMPANIES = 'companies',
  REPORTED_USERS = 'reported-users',
}
