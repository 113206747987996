import { css } from '@emotion/css';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useQueryClient } from 'react-query';
import { Button } from '../../shared/components/Button';
import { Separator } from '../../shared/components/Separator';
import { SubmitButton } from '../../shared/components/SubmitButton';
import { TextArea } from '../../shared/components/TextArea';
import { TextInput } from '../../shared/components/TextInput';
import { MessagingCardActions } from '../../shared/data/enums';
import { IMessagingCard } from '../../shared/data/types/server-types';
import { CacheKeys } from '../../shared/services/api/configuration';
import { useUpdateMessagingCard } from '../../shared/services/api/messaging-cards';
import { Colors } from '../../shared/styles/colors';

type EditMessagingCardData = {
  id: string;
  title: string;
  message: string;
  action: MessagingCardActions;
};

interface IEditMessagingCardModalProps {
  initialValues?: IMessagingCard;
  isOpen: boolean;
  setIsOpen(value: boolean): void;
}

export const EditMessagingCardModal = (props: IEditMessagingCardModalProps) => {
  const { isOpen, setIsOpen, initialValues } = props;
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EditMessagingCardData>();
  const queryClient = useQueryClient();
  const updateMessagingCard = useUpdateMessagingCard();

  useEffect(() => {
    if (initialValues) {
      setValue('id', initialValues?._id);
      setValue('message', initialValues?.message);
      setValue('action', initialValues?.action);
    }
  }, [initialValues]);

  const message = watch('message');
  const action = watch('action');

  const closeClickHandler = () => {
    setIsOpen(false);
  };

  const onSubmit: SubmitHandler<EditMessagingCardData> = async (
    data: EditMessagingCardData,
  ) => {
    await updateMessagingCard({ ...data });
    queryClient.refetchQueries(CacheKeys.MESSAGING_CARDS);
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} style={createNotificationModalStyle}>
      <form onSubmit={handleSubmit(onSubmit)} className={formStyle}>
        <section className={formSectionStyle}>
          <h2>Edit Messaging Card</h2>
          <Separator />
          <TextArea
            placeHolder={'Message'}
            formOptions={register('message', { required: true })}
            errors={errors}
            label={'Message'}
          />
          <h3>Action</h3>
          <Separator />

          <select {...register('action')}>
            <option value={MessagingCardActions.LIST}>
              {MessagingCardActions.LIST}
            </option>
            <option value={MessagingCardActions.SEARCH}>
              {MessagingCardActions.SEARCH}
            </option>
            <option value={MessagingCardActions.SHARE}>
              {MessagingCardActions.SHARE}
            </option>
          </select>
        </section>

        <section className={formSectionStyle}>
          <h2>Summary</h2>
          <Separator />
          <p>Message: {message}</p>
          <p>Action: {action}</p>
        </section>

        <section className={actionButtonsContainerStyle}>
          <Button
            title={'Close'}
            onClick={closeClickHandler}
            style={{ backgroundColor: Colors.RED }}
          />
          <SubmitButton title={'Update'} />
        </section>
      </form>
    </Modal>
  );
};

const formSectionStyle = css`
  margin-bottom: 2rem;
`;

const actionButtonsContainerStyle = css`
  & > * {
    margin-right: 0.5rem;
  }
`;

const formStyle = css`
  position: relative;
`;

const createNotificationModalStyle = {
  content: {
    backgroundColor: Colors.LIGHT_BLUE,
    top: '30%',
    left: '50%',
    right: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -30%)',
    padding: '2rem',
    borderRadius: '1rem',
  },
};
