import { userStore } from '../data/stores/user-store';
import { observer } from 'mobx-react';
import { css } from '@emotion/css';
import { useHistory } from 'react-router';
import { Routes } from '../../routers/routes';
import { Colors } from '../styles/colors';
import { YoungPlanetLogo } from '../assets/icons';
import { Button } from './Button';

export const Header = observer(() => {
  const history = useHistory();
  const user = userStore.getUser();

  const handleLogout = () => {
    userStore.reset();
  };

  return (
    <nav className={headerStyle}>
      {user ? (
        <div>
          <div>
            <YoungPlanetLogo height={30} width={30} />
            <a onClick={() => history.push(Routes.DASHBOARD)}>Dashboard</a>
            <a onClick={() => history.push(Routes.NOTIFICATIONS)}>
              Notifications
            </a>
            <a onClick={() => history.push(Routes.MESSAGING_CARDS)}>
              Messaging Cards
            </a>
            <a onClick={() => history.push(Routes.USERS)}>Users</a>
            <a onClick={() => history.push(Routes.PRODUCTS)}>Products</a>
            <a onClick={() => history.push(Routes.REPORTED_USERS)}>
              Reported users
            </a>
          </div>
          <div>
            <p>Hello, {user.firstName}!</p>
            <Button title={'Logout'} onClick={handleLogout} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </nav>
  );
});

const headerStyle = css`
  box-shadow: 0px 0px 12px ${Colors.SHADOW_COLOR};

  div {
    display: flex;
    flex-direction: flex-row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background-color: ${Colors.WHITE};

    a:first-of-type {
      margin-left: 1rem;
    }

    a {
      color: ${Colors.BLUE};
      cursor: pointer;
      margin-right: 1rem;
    }

    p {
      color: ${Colors.BLUE};
      margin-right: 1rem;
    }
  }
`;
