import { css } from '@emotion/css';
import { useQueryClient } from 'react-query';
import { Button } from '../../shared/components/Button';
import { IProduct } from '../../shared/data/types/server-types';
import { Colors } from '../../shared/styles/colors';
import { formatDateTimeAsString } from '../../shared/utils/format-date';
import { useUpdateProduct } from '../../shared/services/api/products';
import { CacheKeys } from '../../shared/services/api/configuration';

interface IProductsTableProps {
  products: IProduct[];
  skip: number;
  term: string;
}

export const ProductsTable = (props: IProductsTableProps) => {
  const { products, skip, term } = props;
  const queryClient = useQueryClient();
  const updateProduct = useUpdateProduct();

  const onEndListing = async (id: string) => {
    await updateProduct({
      id,
      available: false,
      endListedOn: new Date(),
    });
    await queryClient.refetchQueries([CacheKeys.PRODUCTS, skip, term]);
  };

  const onDelete = async (id: string) => {
    await updateProduct({
      id,
      available: false,
      deleteOn: new Date(),
      deleteItem: true,
    });
    await queryClient.refetchQueries([CacheKeys.PRODUCTS, skip, term]);
  };

  return (
    <table className={tableStyle}>
      <th>ID</th>
      <th>Owner Email</th>
      <th>Owner Name</th>
      <th>Title</th>
      <th>Description</th>
      <th>Available</th>
      <th>Deleted</th>
      <th>Gender</th>
      <th>Age Group</th>
      <th>Given Outside YP</th>
      <th>Keep item</th>
      <th>Given to</th>
      <th>Listed on</th>
      <th>End listed on</th>
      <th>Availability</th>
      <th>Delete</th>
      <tbody>
        {products.map((product: IProduct, index: number) => {
          return (
            <tr key={index}>
              <td>{product._id}</td>
              <td>{product.owner.email}</td>
              <td>{product.owner.fullName}</td>
              <td>{product.title}</td>
              <td>{product.description}</td>
              <td>{product.available ? 'Yes' : 'No'}</td>
              <td>{product.deleteItem ? 'Yes' : 'No'}</td>
              <td>{product.gender}</td>
              <td>{product.ageLimit}</td>
              <td>{product.givenOutsideYoungplanet ? 'Yes' : 'No'}</td>
              <td>{product.keepMyself ? 'Yes' : 'No'}</td>
              <td>{product.givenToWho}</td>
              <td>{formatDateTimeAsString(product.createdOn)}</td>
              <td>
                {product.endListedOn
                  ? formatDateTimeAsString(product.endListedOn)
                  : ''}
              </td>
              <td>
                <Button
                  title={'End Listing'}
                  onClick={() => onEndListing(product._id)}
                  style={{
                    backgroundColor: Colors.RED,
                    padding: 5,
                    borderRadius: 0,
                  }}
                />
              </td>
              <td>
                <Button
                  title={'Delete'}
                  onClick={() => onDelete(product._id)}
                  style={{
                    backgroundColor: Colors.RED,
                    padding: 5,
                    borderRadius: 0,
                  }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const tableStyle = css`
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
  display: block;
  overflow-x: auto;
  white-space: nowrap;

  th {
    text-align: left;
    border-bottom: 1px solid ${Colors.GRAY};
  }

  tr:nth-of-type(2n) {
    background-color: ${Colors.LIGHT_BLUE};
  }

  td {
    padding: 0.7rem;
    border: none;
  }
`;
